import Utils from '../../utils/utils';

export default {
  name: 'utils',
  proto: {
    utils: Utils,
  },
  static: {
    utils: Utils,
  },
};
